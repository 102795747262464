<template>
    <b-card>
      <ModalTaxes :editable="editable" :tax_id="$route.params.id"/>
    </b-card>
  </template>
  
<script>
  import {
    BCard,
  } from 'bootstrap-vue';
  import ModalTaxes from './ModalTaxes.vue';
  export default {
      components: {
          BCard,
          ModalTaxes
      },
      data(){
          return{
          editable: true
      }}
  }
</script>
